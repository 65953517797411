import { createSelector } from '@reduxjs/toolkit'
import moment from 'moment'
import { IGrant, ISolicitation } from '../../types/Grants'
import { convertPhase } from '../../utils'
import { RootState } from '../store'

const getGrantsError = (state: RootState) => state.grantMatch.grants.error
const getGrantsFetching = (state: RootState) => state.grantMatch.grants.fetching
const getGrantsResponseData = (state: RootState) => state.grantMatch.grants.data || []

const getGrantResponseData = (state: RootState) => state.grantMatch.grant.data
const getGrantFetching = (state: RootState) => state.grantMatch.grant.fetching
const getGrantError = (state: RootState) => state.grantMatch.grant.error

const makeGrantsResponses = createSelector(
  getGrantsResponseData,
  (state: RootState, grantType: string) => grantType,
  (grants: any, grantType = 'all') => {
    return grants
      .filter((_grant: any) => {
        if (grantType !== 'all') return _grant && _grant.status && _grant.status === grantType
        return true
      })
      .map((_grant: any) => {
        const {
          subtopicNumber = '',
          topicNumber = '',
          technologyId = '',
          score = 0,
          like = 0,
          grant = {},
          solicitation = {},
        } = _grant

        const {
          _id: grantId = '',
          topic_title: topicTitle = '',
          description: topicDescription = 'N/A',
          subtopic_title: subtopicTitle = 'N/A',
          title = '',
        } = grant as IGrant

        const {
          agency = '',
          close_date = '',
          _id: solicitationId = '',
          current_status: currentStatus = '',
          solicitation_year: solicitationYear = '',
          solicitation_title: solicitationTitle = 'N/A',
          phase: grantPhase = '',
          solicitation_number: solicitationNumber = '',
        } = solicitation as ISolicitation

        const dueDate = close_date ? moment.utc(close_date).format('MM/DD/YYYY') : 'N/A'

        const phaseValue = convertPhase(grantPhase)

        return {
          ..._grant,
          grantId,
          technologyId,
          topicNumber,
          subtopicNumber,
          score,
          phaseValue,
          like,
          currentStatus,
          agency,
          dueDate,
          title,
          topicDescription,
          topicTitle,
          subtopicTitle,
          solicitationId,
          solicitationNumber,
          solicitationTitle,
          solicitationYear,
        }
      })
  },
)

const makeGrant = createSelector(getGrantResponseData, (matchedGrant) => {
  const { _id: matchedGrantId = '', grant = {} as IGrant, ...rest } = matchedGrant
  const { _id: grantId = '', ..._grant } = grant

  return {
    _id: grantId,
    matchedGrantId,
    ...rest,
    ..._grant,
  }
})

const makeGrantById = createSelector(
  makeGrantsResponses,
  (_: any, id: string) => id,
  (grants, id) => {
    const grant = grants.find((g: any) => g.grantId === id)
    const Description =
      (grant && grant.topicDescription.replace(/^[\s\r\n]+|[\s\r\n]+$/g, '')) || ''

    return { ...grant, Description }
  },
)

const makeTechGrantPayload = createSelector(
  makeGrantsResponses,
  (_: any, id: string) => id,
  (grants, id) => {
    const grant = grants.find((g: any) => g.grantId === id)

    if (grant) {
      return {
        grantId: grant.grantId,
        topicId: grant.topicId,
        solicitationNumber: grant.solicitationNumber,
        topicNumber: grant.topicNumber || '',
        subtopicNumber: grant.subtopicNumber || '',
      }
    }

    return {}
  },
)

const matchedGrantsCount = createSelector(getGrantsResponseData, (grants) => {
  const matchedGrants =
    grants && grants.filter((grant: any) => grant && grant.status === 'recommended')
  return matchedGrants.length || 0
})

export const GrantMatcherSelector = {
  getGrantError,
  getGrantsError,
  getGrantFetching,
  getGrantsFetching,
  makeGrant,
  makeGrantById,
  matchedGrantsCount,
  makeGrantsResponses,
  makeTechGrantPayload,
}

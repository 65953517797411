import { Box, List, ListItem, Stack, Typography } from '@mui/material'
import { FC } from 'react'
import { JSX } from 'react/jsx-runtime'

export const GrantDescription: FC<{ description: string | object }> = ({ description }) => {
  const splitDescription = (description: string) => {
    const d = description.split('\n')
    let currEleChildren: JSX.Element[] = []

    //reg_exp not working on alphabets except "a. "
    const bullet_rgx = /^(\u2022|\u2023|\u25E6|\u2043|\u2219|\d+\.+\s|[a-zA-Z]+\.\s)/
    const bold_rgx = /<b>(.*?)<\/b>/g

    return d.reduce((acc, curr) => {
      const bulletMatch = curr.match(bullet_rgx)
      const boldMatch = curr.match(bold_rgx)

      if (boldMatch) {
        const parts = curr.split(bold_rgx).map((part, index) => (
            <Typography
                key={index}
                mt={1}
                fontSize="1rem"
                fontWeight={700}
                color="secondary.dark"
                whiteSpace="break-spaces"
            >
              {part}
            </Typography>
        ));

        acc.push(<Box>{parts}</Box>)
      } else if (bulletMatch) {
        const str = curr.replace(bullet_rgx, '').trim()

        currEleChildren.push(
            <ListItem
                sx={{
                  display: 'list-item',
                  fontSize: '1rem',
                  color: 'secondary.light',
                }}
            >
              {str}
            </ListItem>
        );
      }

      else {
        if (currEleChildren.length) {
          acc.push(
              <List
                  sx={{
                    ml: 2,
                    listStyleType: 'disc',
                    listStylePosition: 'inside',
                  }}
              >
                {currEleChildren}
              </List>
          );
          currEleChildren = []
        } else {
          acc.push(
              <Typography
                  mt={1}
                  variant="body1"
                  fontSize="1rem"
                  color="secondary.light"
                  whiteSpace="break-spaces"
              >
                {curr.trim()}
              </Typography>
          );
        }
      }

      return acc
    }, [] as any)
  }

  if (typeof description === 'object') {
    return (
      <Box my={3}>
        {Object.entries(description).map(([key, value]) => (
          <Stack mb={2} gap={0.5} key={key}>
            <Typography fontSize="1rem" fontWeight={700} color="secondary.main">
              {key}
            </Typography>
            <Typography fontSize="0.875rem" color="secondary.light" whiteSpace="break-spaces">
              {value}
            </Typography>
          </Stack>
        ))}
      </Box>
    )
  } else {
    return <Box my={3}>{splitDescription(description)}</Box>
  }
}
